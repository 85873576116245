<template>
  <nav>
    <div class="pos__rel">
      <div class="exit-icon" @click="startSetMenuToggled">
        <font-awesome-icon icon="times" size="2x" />
      </div>
    </div>
    <div class="flex__center">
      <router-link to="/" @click.native="startSetMenuToggled" name="Giving Branch home page">
        <img
          class="logo"
          src="https://cdn.fbhl.com/foundation/logos/giving-branch-horizontal-white.png"
          alt="Click to return to home page"
          width="100%"
          height="100%"
        />
      </router-link>
    </div>
    <div>
      <simplebar class="nav--overflow" data-simplebar-auto-hide="fals">
        <ul>
          <router-link
            to="/donation-request"
            name="request financial assistance"
            @click.native="startSetMenuToggled"
          >
            <li>
              Donation
              Request
            </li>
          </router-link>
          <router-link
            to="/scholarship"
            name="Learn about our scholarship"
            @click.native="startSetMenuToggled"
          >
            <li>Scholarships</li>
          </router-link>
          <router-link
            to="/recipient-stories"
            name="Read some stories from people we have helped"
            @click.native="startSetMenuToggled"
          >
            <li>Recipient Stories</li>
          </router-link>
          <router-link
            to="/who-we-are"
            name="Learn more about The Giving Branch"
            @click.native="startSetMenuToggled"
          >
            <li>Who We Are</li>
          </router-link>
          <router-link
            to="/our-impact"
            name="Explore the impact we have had"
            @click.native="startSetMenuToggled"
          >
            <li>Our Impact</li>
          </router-link>
          <!-- <router-link
            to="/down-payment-assistance"
            name="Down Payment Assistance Program"
            @click.native="startSetMenuToggled"
          >
            <li>Down Payment Assistance Program</li>
          </router-link> -->
        </ul>
      </simplebar>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions(["startSetMenuToggled"])
  }
};
</script>

<style scoped lang="scss">
nav {
  @include pos-abs(
    $pos: fixed,
    $top: 0px,
    $left: null,
    $right: 0,
    $height: 100vh,
    $width: $nav-width
  );
  @include grid($cols: 1fr, $rows: 10px 85px 1fr, $row-gap: 15px);
  background-color: $pine;
  @include shadow;
}
.exit-icon {
  color: $powder;
  @include pos-abs($pos: absolute, $left: null, $right: 5px);
}
.logo {
  width: 150px;
  height: 70px;
}
.nav--overflow {
  max-height: calc(100vh - 150px);
}
ul {
  list-style: none;
  background: linear-gradient(to right, $pine, $pistachio, $pine);
  @include grid($cols: 1fr, $row-gap: 3px);
  padding: 3px 0;
  a {
    text-decoration: none;
    font-size: $text-lg;
    font-weight: bold;
    text-align: center;
  }
}
li {
  background-color: $pine;
  padding: 25px 0;
  color: $pistachio;
}
.router-link-exact-active > * {
  color: $powder;
}
</style>
